import React from 'react'
import DynamicForm from './DynamicForm'

export default class ArrayField extends React.Component {
  constructor(props) {
    super(props)

    this.count = 0
    this.ids = new Map()
    var value = props.value || []
    value.forEach((item, index) => {
      this.ids.set(item, ++this.count)
    })

    this.state = {
      value: value,
      options: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleItemChangeFromForm = this.handleItemChangeFromForm.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onMoveUp = this.onMoveUp.bind(this);
    this.onMoveDown = this.onMoveDown.bind(this);
  }

  handleChange(event) {
    const value = event.target.value
    let arrayValue = value.split(',')

    this.setState({
      value: arrayValue
    })
    this.props.onChange(this.props.fieldName, arrayValue)
  }

  handleItemChange(index, event) {
    const itemValue = event.target.value
    const arrayValue = this.state.value
    arrayValue[index] = itemValue

    this.setState({
      value: arrayValue
    })

    this.props.onChange(this.props.fieldName, arrayValue)
  }

  handleItemChangeFromForm(index, itemValue) {
    const arrayValue = this.state.value
    arrayValue[index] = itemValue

    this.setState({
      value: arrayValue
    })
    this.props.onChange(this.props.fieldName, arrayValue)
  }

  onAdd(e) {
    e.preventDefault();
    const config = this.props.config

    let template = ''
    if (config.itemType === 'object') {
      template = {}
      this.ids.set(template, ++this.count)
    }
    const arrayValue = this.state.value
    arrayValue.push(template)
    this.setState({
      value: arrayValue
    })
  }

  onDelete(index, e) {
    e.preventDefault();
    const arrayValue = this.state.value
    arrayValue.splice(index, 1)
    this.setState({
      value: arrayValue
    })
  }

  onMoveUp() {

  }

  onMoveDown() {

  }

  componentDidMount() {
  }

  render() {
    const config = this.props.config

    let value = this.state.value

    const items = []
    let component, key
    value.forEach((val, index) => {
      if (config.itemType === 'object') {
        component = (
          <DynamicForm
            data={val}
            config={config.fields}
            nested="true"
            onChange={this.handleItemChangeFromForm.bind(this, index)}
          />
        )
      } else {
        component = (          
          <input className="dynamic-form-widget" type="text" value={val} onChange={this.handleItemChange.bind(this, index)} />
        )
      }

      if (config.itemType === 'object') {
        key = this.ids.get(val)
      } else {
        key = 'item' + index
      }

      items.push(
        <div key={key}>
          <button className="link-button" onClick={this.onDelete.bind(this, index)}>delete</button>  
          &nbsp;
          <button className="link-button" onClick={this.onMoveUp.bind(this, index)}>up</button>  
          &nbsp;
          <button className="link-button" onClick={this.onMoveDown.bind(this, index)}>down</button>
          {component}
        </div>
      )
    })

    return (
      <div>
        {items}
        <button className="link-button" onClick={this.onAdd}>add</button>
      </div>
    )
  }
}
