import React from 'react';

export default class Kanban extends React.Component {
  constructor(props) {
    super(props)
  }

  static getConfiguration() {
    return [
      {
        name: 'stateField',
        type: 'string'
      },
      {
        name: 'labelField',
        type: 'string'
      },
      {
        name: 'descriptionField',
        type: 'string'
      },
      {
        name: 'showNoState',
        type: 'bool'
      },
      {
        name: 'columns',
        type: 'array',
        itemType: 'object',
        fields: [
          {
            name: 'name',
            type: 'string'
          },
          {
            name: 'max',
            type: 'number'
          }
        ]
      }
    ]
  }

  componentDidMount() {
    // const node = ReactDOM.findDOMNode(this)

    // let oldPosition = null
    // jQuery(node).sortable({
    //   cursor: "move",
    //   opacity: 1,
    //   tolerance: 'pointer',
    //   start: function(event, ui) {
    //     oldPosition = ui.item.index();
    //   },
    //   update: (event, ui) => {
    //     const data = this.props.data
    //     const newPosition = ui.item.index()

    //     const item = data[oldPosition]
    //     this.props.onSort(item, newPosition, oldPosition)

    //     jQuery(node).sortable('cancel')
    //     this.forceUpdate()
    //   }
    // })
  }

  render() {
    const props = this.props
    const config = this.props.config || {}

    const containerStyle = {
      width: '100%',
      border: '1px solid black',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch'
    }

    const itemStyle = {
      // height: '80px',
      backgroundColor: '#eee',
      border: '1px solid #73AD21',
      borderRadius: '5px',
      overflow: 'auto',
      textAlign: 'center',
      padding: '2px', // padding inside the box
      margin: '10px' // space between boxes
    }

    const columns = []
    let items = []
    const colDefs = config.columns || []
    const data = this.props.data || []

    if (config.showNoState) {
      const columnStyle = getColumnStyle()
      items = getItems(data, null, config)
      columns.push(
        <div key="-1" style={columnStyle}>
          No state
          {items}
        </div>
      )
    }

    if (colDefs && data) {
      colDefs.forEach((col, i) => {
        let colName = col
        let max = null
        if (typeof col === 'object') {
          colName = col.name
          max = col.max
        }

        items = getItems(data, colName, config)

        const columnStyle = getColumnStyle(max, items.length)

        // if (col === undefined) {
        //   col = '- not set -'
        // }

        columns.push(
          <div key={i} style={columnStyle}>
            {colName}
            {items}
          </div>
        )
      })
    }

    return (
      <div style={containerStyle}>
        {columns}
      </div>
    )

    function getItems(data, state, config) {
      const stateField = config.stateField || 'state'
      const items = []
      const colors = ['#eee', '#fcc', '#f99', '#f66', '#f33', '#f00']

      data.filter(item => {
        if (state === null) {
          return item[stateField] === null || item[stateField] === undefined
        }
        return item[stateField] === state
      })
        .forEach((item, j)  => {
          const style = Object.assign({}, itemStyle)
          if (item[stateField] !== 'done') {
            const timeUntouched = Date.now() - item['_dateModified']
            const days = Math.floor(timeUntouched / (24 * 60 * 60 * 1000))
            if (days < colors.length) {
              style.backgroundColor = colors[days]
            } else {
              style.backgroundColor = colors[colors.length - 1]
            }
          }

          items.push(
            <div id={item._id} key={j} style={style}>
              <div className="link-box" onClick={props.onEdit.bind(this, item)}>
                {item[config.labelField]}
              </div>
              <br/>
              {item[config.descriptionField]}
            </div>
          )
      })
      return items
    }

    function getColumnStyle(max, itemCount) {
      const columnStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        margin: '10px'
      }

      if (max && itemCount > max) {
        columnStyle.backgroundColor = '#f00'
      }

      return columnStyle
    }
  }
}
