import React from 'react';
import { getEntityByName } from '../../util/store'
import BasicTable from './BasicTable'
import DynamicForm from '../form/DynamicForm'

export default class Budget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entity: null,
      showModal: false,
      hasError: false
    }

    this.onEdit = this.onEdit.bind(this)
    this.onEditItem = this.onEditItem.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCloseEditItem = this.handleCloseEditItem.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  static getConfiguration() {
    return [
      {
        name: 'valueField',
        type: 'string',
      },
      {
        name: 'groupByField',
        type: 'string'
      },
      {
        name: 'showTotal',
        type: 'bool'
      },
      {
        name: 'showUnplanned',
        type: 'bool'
      },
      {
        name: 'groups',
        type: 'array',
        itemType: 'object',
        fields: [
          {
            name: 'group',
            type: 'string'
          },
          {
            name: 'value',
            type: 'number'
          },
          {
            name: 'variable',
            type: 'bool'
          }
        ]
      }
    ]
  }

  componentDidMount() {
    getEntityByName(this.props.dataConfig.entity)
      .then(entity => {
        if (entity && entity.length) {
          this.setState({
            entity: entity[0]
          })
        }
      })
  }

  onEdit(row) {
    this.setState({
      showModal: true,
      items: row.items,
      modalState: 'showDetails'
    })
  }

  onEditItem(row) {
    this.props.onEdit(row)
  }

  handleCloseModal () {
    this.setState({
      showModal: false
    });
  }

  handleCloseEditItem(data) {
    if (this.state.modalCallback) {
      this.state.modalCallback(data)
    }
    this.setState({
      modalState: 'showDetails'
    });
  }

  handleSubmit() {
    this.setState({
      showModal: true,
      modalState: 'showDetails'
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    const UNPLANNED = 'Unplanned'

    let config = this.props.config
    if (!config) {
      return (
        <div>Not configured</div>
      )
    }

    const itemsTableConfig = {
      showEdit: true,
      columns: this.state.entity ? this.state.entity.fields : []
    }

    // create table configuration (columns)
    const tableConfig = {
      showEdit: true,
      columns: [
        {
          name: 'group'
        },
        {
          name: 'planned'
        },
        {
          name: 'actual'
        },
        {
          name: 'remaining'
        },
        {
          name: 'over'
        },
        {
          name: 'variable'
        }
      ]
    }

    // create records
    const buckets = {}
    if (config.groups) {
      config.groups.forEach(group => {
        buckets[group.group] = {
          group: group.group,
          actual: 0,
          planned: group.value,
          variable: group.variable,
          items: []
        }
      })
    }

    if (config.showUnplanned) {
      buckets[UNPLANNED] = {
        group: 'Unplanned',
        actual: 0,
        planned: 0,
        items: []
      }
    }

    const data = this.props.data || []

    // create map of record objects
    const valueField = config.valueField
    const groupByField = config.groupByField
    data.forEach(item => {
      let groupName = item[groupByField]
      if (buckets[groupName]) {
        buckets[groupName].actual += item[valueField]
        buckets[groupName].items.push(item)
      } else if (config.showUnplanned) {
        buckets[UNPLANNED].actual += item[valueField]
        buckets[UNPLANNED].items.push(item)
      }
    })

    let rows = Object.keys(buckets).map(key => {
      const bucket = buckets[key]
      bucket.remaining = (bucket.planned - bucket.actual).toFixed(2)
      bucket.actual = bucket.actual.toFixed(2)
      if (bucket.remaining < 0) {
        bucket.over = bucket.remaining
        bucket.remaining = 0
      }
      return bucket
    })

    if (config.showTotal) {
      let allPlanned = 0, allActual = 0
      rows.forEach(row => {
        allActual += Number(row.actual)
        allPlanned += Number(row.planned)
      })

      rows.push({
        group: 'Total',
        planned: allPlanned,
        actual: allActual.toFixed(2),
        remaining: (allPlanned - allActual).toFixed(2)
      })
    }

    return (
      <div>
        <BasicTable
          data={rows}
          config={tableConfig} 
          onEdit={this.onEdit}
        />
        {this.state.showModal &&
        <div className="fixedpanel">
          {this.state.modalState === 'showDetails' &&
          <div>
            <button onClick={this.handleCloseModal}>Close</button>
            <BasicTable
              data={this.state.items}
              config={itemsTableConfig} 
              onEdit={this.onEditItem}
            />
          </div>
          }
        </div>
        }
      </div>
    )
  }
}
