import React from 'react'
// import observeComponent from '../observable/observeComponent'
// const MeasureRender from '../observable/MeasureRender')

import DashboardItem from './dashboard/DashboardItem'
import EntityForm from './EntityForm'
// const DashboardItem = observeComponent(from './dashboard/DashboardItem.react'))
// const EntityForm = observeComponent(from './EntityForm.react'))
import DefaultLayout from './layouts/DefaultLayout'
import ColumnLayout from './layouts/ColumnLayout'
import GridLayout from './layouts/GridLayout'
import DraggableGridLayout from './layouts/DraggableGridLayout'
import { getUrlParamsAsMap } from '../util/url'
import { withRouter } from "react-router-dom"
import { deleteDashboard, updateDashboard } from '../util/store'
import DynamicForm from './form/DynamicForm'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.refreshCounter = 0

    this.state = {
      showModal: false,
      editing: false
    }

    this.layoutConfig = null

    this.handleNew = this.handleNew.bind(this)
    this.handleUpdateRecord = this.handleUpdateRecord.bind(this)
    this.handleDeleteRecord = this.handleDeleteRecord.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleConfigureItem = this.handleConfigureItem.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleEditItem = this.handleEditItem.bind(this)
    this.handleCloneItem = this.handleCloneItem.bind(this)
    this.onConfigureLayoutStarted = this.onConfigureLayoutStarted.bind(this)
    this.onConfigureLayoutFinished = this.onConfigureLayoutFinished.bind(this)
    this.onDashboardDelete = this.onDashboardDelete.bind(this)
    this.onDashboardEdit = this.onDashboardEdit.bind(this)
    this.onLayoutChange = this.onLayoutChange.bind(this)
    this.onNewItem = this.onNewItem.bind(this)
  }

  onDashboardDelete() {
    deleteDashboard(this.props.config._id)
      .then(() => {
        this.props.onDeleted()
        this.props.history.push('/')
      })
  }

  onDashboardEdit() {
    const dashboardConfig = this.props.config

    const dashboardFormConfig = [
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'category',
        type: 'string'
      },
      {
        name: 'layout',
        type: 'object',
        fields: [
          {
            name: 'type',
            type: 'string',
            inputType: 'combo',
            options: ['simple', 'columns', 'grid', 'draggable'],
            default: 'draggable'
          },
          {
            name: 'columns',
            type: 'string',
            condition: {
              field: 'type',
              value: 'columns'
            }
          },
          {
            name: 'items',
            type: 'array',
            itemType: 'object',
            condition: {
              field: 'type',
              value: 'columns'
            },
            fields: [
              {
                name: 'column',
                type: 'string'
              }
            ]
          }
        ]
      }
    ]

    const data = {
      name: dashboardConfig.name,
      category: dashboardConfig.category,
      layout: dashboardConfig.layout
    }

    this.setState({
      showModal: true,
      modalState: 'editDashboard',
      modalData: data,
      modalConfig: dashboardFormConfig,
      modalCallback: save
    });

    function save(data) {
      dashboardConfig.name = data.name
      dashboardConfig.category = data.category
      if (data.layout.type !== dashboardConfig.layout.type) {
        dashboardConfig.layout = data.layout
      }

      updateDashboard(dashboardConfig)
        .then(() => {
        })
    }
  }

  // called when 'new item' button is clicked to add a new dashboard item
  onNewItem() {
    const dashboardData = this.props.config

    const data = {
      title: 'new item',
      type: 'table',
      data: {
        entity: '',
        filter: '',
        sort: ''
      }
    }

    const config = [
      {
        name: 'title',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string',
        inputType: 'combo',
        options: ['table', 'kanban', 'cards', 'note', 'quote', 'aggregated table', 'budget'],
      },
      {
        name: 'data',
        type: 'object',
        fields: [
          {
            name: 'entity',
            type: 'string'
          },
          {
            name: 'filter',
            type: 'string'
          },
          {
            name: 'sort',
            type: 'string'
          }
        ]
      }
    ]

    this.setState({
      showModal: true,
      modalState: 'newItem',
      modalData: data,
      modalConfig: config,
      modalCallback: save
    });

    function save(data) {
      data._id = Date.now().toString()
      dashboardData.items.push(data)
      updateDashboard(dashboardData)
        .then(() => {
        })
    }
  }

  handleCloneItem(item) {
    const component = this
    const dashboardData = this.props.config

    const config = [
      {
        name: 'title',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string',
        inputType: 'combo',
        options: ['table', 'kanban', 'cards', 'note', 'quote', 'aggregated table', 'budget'],
      },
      {
        name: 'data',
        type: 'object',
        fields: [
          {
            name: 'entity',
            type: 'string'
          },
          {
            name: 'filter',
            type: 'string'
          },
          {
            name: 'sort',
            type: 'string'
          }
        ]
      },
      {
        name: 'form',
        type: 'object',
        fields: [
          {
            name: 'showUndefinedFields',
            type: 'bool'
          },
          {
            name: 'fields',
            type: 'array',
            itemType: 'object',
            fields: [
              {
                name: 'name',
                type: 'string'
              },
              {
                name: 'editable',
                type: 'bool',
                default: true
              },
              {
                name: 'array',
                type: 'bool',
                default: false
              },
              {
                name: 'default',
                type: 'string'
              },
              {
                name: 'inputType',
                type: 'string',
                inputType: 'combo',
                options: ['textfield', 'combo', 'creatable', 'textarea']
              },
              {
                name: 'options',
                type: 'array',
                itemType: 'string',
                condition: {
                  field: 'inputType',
                  value: 'combo'
                }
              },
              {
                name: 'width',
                type: 'string',
                condition: {
                  field: 'inputType',
                  value: 'textarea'
                }
              },
              {
                name: 'height',
                type: 'string',
                condition: {
                  field: 'inputType',
                  value: 'textarea'
                }
              },
              {
                name: 'options',
                type: 'object',
                fields: [
                  {
                    name: 'entity',
                    type: 'string'
                  },
                  {
                    name: 'field',
                    type: 'string'
                  },
                  {
                    name: 'filter',
                    type: 'string'
                  }
                ],
                condition: {
                  field: 'inputType',
                  value: 'creatable'
                }
              },
              {
                name: 'multi',
                type: 'bool',
                condition: {
                  field: 'inputType',
                  value: 'creatable'
                }
              }
            ]
          }
        ]
      }
    ]

    var clone = Object.assign({}, item)
    clone._id = Date.now().toString()
    dashboardData.items.push(clone)

    this.setState({
      showModal: true,
      modalState: 'editItem',
      modalData: clone,
      modalConfig: config,
      modalCallback: save
    });

    function save(data) {
      // this is temporary until figure out a better way
      if (data.form && data.form.fields) {
        data.form.fields.forEach(field => {
          if (field.array && typeof field.default === 'string') {
            field.default = field.default.split(',')
          }
        })
      }

      updateDashboard(dashboardData)
        .then(() => {
          component.forceUpdate() 
        })
    }
  }
  
  handleEditItem(item) {
    const component = this
    const dashboardData = this.props.config

    const config = [
      {
        name: 'title',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string',
        inputType: 'combo',
        options: ['table', 'kanban', 'cards', 'note', 'quote', 'aggregated table', 'budget'],
      },
      {
        name: 'data',
        type: 'object',
        fields: [
          {
            name: 'entity',
            type: 'string'
          },
          {
            name: 'filter',
            type: 'string'
          },
          {
            name: 'sort',
            type: 'string'
          }
        ]
      },
      {
        name: 'form',
        type: 'object',
        fields: [
          {
            name: 'showUndefinedFields',
            type: 'bool'
          },
          {
            name: 'fields',
            type: 'array',
            itemType: 'object',
            fields: [
              {
                name: 'name',
                type: 'string'
              },
              {
                name: 'editable',
                type: 'bool',
                default: true
              },
              {
                name: 'array',
                type: 'bool',
                default: false
              },
              {
                name: 'default',
                type: 'string'
              },
              {
                name: 'inputType',
                type: 'string',
                inputType: 'combo',
                options: ['textfield', 'combo', 'creatable', 'textarea']
              },
              {
                name: 'options',
                type: 'array',
                itemType: 'string',
                condition: {
                  field: 'inputType',
                  value: 'combo'
                }
              },
              {
                name: 'width',
                type: 'string',
                condition: {
                  field: 'inputType',
                  value: 'textarea'
                }
              },
              {
                name: 'height',
                type: 'string',
                condition: {
                  field: 'inputType',
                  value: 'textarea'
                }
              },
              {
                name: 'options',
                type: 'object',
                fields: [
                  {
                    name: 'entity',
                    type: 'string'
                  },
                  {
                    name: 'field',
                    type: 'string'                
                  },
                  {
                    name: 'filter',
                    type: 'string'
                  }
                ],
                condition: {
                  field: 'inputType',
                  value: 'creatable'
                }
              },
              {
                name: 'multi',
                type: 'bool',
                condition: {
                  field: 'inputType',
                  value: 'creatable'
                }
              }
            ]
          }
        ]
      }
    ]

    this.setState({
      showModal: true,
      modalState: 'editItem',
      modalData: item,
      modalConfig: config,
      modalCallback: save
    });

    function save(data) {
      // this is temporary until figure out a better way
      if (data.form && data.form.fields) {
        data.form.fields.forEach(field => {
          if (field.array && typeof field.default === 'string') {
            field.default = field.default.split(',')
          }
        })
      }

      updateDashboard(dashboardData)
        .then(() => {
          component.refreshCounter++
          component.forceUpdate() 
        })
    }
  }

  handleNew(entityName, callback, formConfig) {
    const me = this
    
    this.setState({
      showModal: true,
      modalState: 'newRecord',
      modalEntityName: entityName,
      modalCallback: finished,
      modalConfig: formConfig
    });

    function finished(data) {
      callback(data) 
      me.refreshCounter++
      me.forceUpdate()
    }
  }

  onConfigureLayoutStarted() {
    this.setState({
      editing: true
    })
  }

  onConfigureLayoutFinished(ev) {
    this.setState({
      editing: false
    })

    const dashboardData = this.props.config
    dashboardData.layout.config = this.layoutConfig
    updateDashboard(dashboardData)
  }

  onLayoutChange(config) {
    this.layoutConfig = config
  }

  handleConfigureItem(config, formConfig) {
    const dashboardData = this.props.config

    this.setState({
      showModal: true,
      modalState: 'configure',
      modalData: config.config,
      modalConfig: formConfig,
      modalCallback: save
    });

    function save(data) {
      config.config = data
      updateDashboard(dashboardData)
    }
  }

  handleDeleteItem(item) {
    const dashboardData = this.props.config

    const index = dashboardData.items.indexOf(item)
    dashboardData.items.splice(index, 1)
    updateDashboard(dashboardData)
      .then(() => {
        this.forceUpdate()
      })
  }

  handleUpdateRecord(entityName, data, callback, formConfig) {
    var me = this
    this.setState({
      showModal: true,
      modalState: 'update',
      modalEntityName: entityName,
      modalData: data,
      modalCallback: finished,
      modalConfig: formConfig
    });

    function finished(data) {
      callback(data) 
      me.refreshCounter++
      me.forceUpdate()
    }
  }

  handleDeleteRecord(entityName, data, callback) {
  }

  // called by EntityForm when Submit button is clicked
  handleSubmit(data) {
    if (this.state.modalCallback) {
      this.state.modalCallback(data)
    }
    this.handleCloseModal()
  }

  handleCloseModal () {
    this.setState({
      showModal: false,
      modalState: null,
      modalEntityName: null
    });
  }

  handleReactMethodCalled(obj, method, args) {
    // console.log(method, args)
  }

  render() {
    const config = this.props.config

    if (!config) {
      return this.renderLoading()
    }

    const name = config.name || ''
    let data = null
    if (this.state.modalState === 'update') {
      data = this.state.modalData
    } else if (this.state.modalState === 'configure') {
      // TODO: get data from the current dashboard item
    }

    const items = []
    const refs = []
    config.items.map((item, index) => {
      const params = getUrlParamsAsMap()
      let key = item._id
      if (!key) {
        key = index
      }

      let ref = React.createRef()
      refs.push(ref)

      return (
        <div key={key} id={'item' + index} ref={ref}>
          <DashboardItem
            key={index}
            config={item}
            params={params}
            onNew={this.handleNew}
            onEditRecord={this.handleUpdateRecord}
            onDeleteRecord={this.handleDeleteRecord}
            onConfigure={this.handleConfigureItem}
            onDelete={this.handleDeleteItem}
            onEdit={this.handleEditItem}
            onClone={this.handleCloneItem}
            refresh={this.refreshCounter}
          />
        </div>
      )
    })
    .forEach(item => items.push(item))

    // layout
    const layoutConfig = config.layout
    // layoutConfig.items = config.items.map((item) => {
    //   return item.layout || {}
    // })

    let cls
    if (layoutConfig.type === 'columns') {
      cls = ColumnLayout
    } else if (layoutConfig.type === 'grid') {
      cls = GridLayout
    } else if (layoutConfig.type === 'draggable') {
      cls = DraggableGridLayout
    } else {
      cls = DefaultLayout
    }

    const layout = React.createElement(cls, {
      items: items,
      refs: refs,
      config: layoutConfig,
      edit: this.state.editing,
      onLayoutChange: this.onLayoutChange
    })

    return (
      <div className="dashboard">
        <h1>{name}</h1>
        {!this.state.editing &&
        <button className="link-button" onClick={this.onConfigureLayoutStarted}>edit layout</button>
        }
        {this.state.editing &&
        <button className="link-button" onClick={this.onConfigureLayoutFinished}>save layout</button>
        }
        &nbsp;&nbsp;
        <button className="link-button" onClick={this.onDashboardEdit}>edit dashboard</button>
        &nbsp;&nbsp;
        <button className="link-button" onClick={this.onDashboardDelete}>delete dashboard</button>
        &nbsp;&nbsp;
        <button className="link-button" onClick={this.onNewItem}>new item</button>
        {layout}
        {this.state.showModal &&
        <div className="fixedpanel">
            {(this.state.modalState === 'newRecord' || this.state.modalState === 'update') &&
            <EntityForm entityName={this.state.modalEntityName}
              entity={this.state.modalEntity}
              config={this.state.modalConfig}
              data={data} onSubmit={this.handleSubmit}
              observable={{ methodCallback: this.handleReactMethodCalled }}/>
            }
            {(this.state.modalState == 'newItem' || this.state.modalState === 'editItem' || this.state.modalState == 'configure' || this.state.modalState === 'editDashboard') &&
            <DynamicForm
              data={this.state.modalData}
              config={this.state.modalConfig}
              onSubmit={this.handleSubmit}
            />
            }
          <button onClick={this.handleCloseModal}>Close</button>
        </div>
        }
      </div>
    )
  }

  renderLoading() {
    return (
      <div>select dashboard</div>
    )
  }
}

export default withRouter(Dashboard)
