import React from 'react'
import { getData, getEntityByName, getEntities, updateRecord } from '../util/store'
import Table from './dashboard/Table'
import EntityForm from './EntityForm'

export default class QueryView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      entities: [],
      entity: null
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEntityChange = this.handleEntityChange.bind(this);
    this.onEditRecord = this.onEditRecord.bind(this);
    this.handleSubmitRecord = this.handleSubmitRecord.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentDidMount() {
    getEntities()
      .then(entities => {
        this.setState({
          entities: entities
        })
      })

  }

  handleEntityChange(e) {
    const value = e.target.value

    const entity = this.state.entities.find(ent => 
      ent.name === value
    )

    this.setState({
      entity: entity,
      data: []
    })

    if (entity) {
      getEntityByName(entity.name)
        .then(entity => {
          if (entity && entity.length) {
            this.setState({
              entity: entity[0]
            })
          }
        })
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const query = {
      entity: this.state.entity.name,
      filter: this.state.query,
      sort: '_dateCreated desc'
    }

    getData(query)
      .then(data => {
        this.setState({
          data: data
        })
      })
  }

  handleSubmitRecord(data) {
    const entityName = this.state.entity.name
    updateRecord(entityName, data)
      .then((response) => {
        // this is now handled from the parent Dashboard
        // this.loadData()
      })
    this.handleCloseModal()
  }

  handleChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  onEditRecord(data) {
    var me = this

    var config = {
      showUndefinedFields: true
    }

    this.setState({
      showModal: true,
      modalState: 'update',
      modalEntity: this.state.entity,
      modalData: data,
      modalConfig: config
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
      modalState: null,
      modalEntityName: null
    });
  }

  render() {
    const value = this.state.query

    const config = {
      showEdit: true,
      columns: []
    }

    if (this.state.entity) {
      this.state.entity.fields.forEach(field => {
        config.columns.push(field.name)
      })
      config.columns.push('_dateCreated')
      config.columns.push('_dateModified')
    }

    let options = []
    let selectedEntity = (this.state.entity && this.state.entity.name) || ''
    if (Array.isArray(this.state.entities)) {
      options = this.state.entities.map((o, i) => {
        return (
            <option key={o.name}>{o.name}</option>
          )
      })
      options.unshift(<option key={-1}></option>)
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div>
          <select value={selectedEntity} onChange={this.handleEntityChange}>
            {options}
          </select>
        </div>
        <div>
          <textarea className="search-box" value={value} onChange={this.handleChange} />
        </div>
        <div>
          <input type="button" className="search-button" value="search" onClick={this.handleSubmit} />
        </div>
        <div>
          <Table data={this.state.data} dataConfig={{ entity: 'item' }} onEdit={this.onEditRecord} config={config} />
        </div>

        {this.state.showModal &&
        <div className="fixedpanel">
            {(this.state.modalState === 'update') &&
            <EntityForm entityName={this.state.modalEntityName}
              entity={this.state.modalEntity}
              config={this.state.modalConfig}
              data={this.state.modalData} onSubmit={this.handleSubmitRecord} />
            }
          <button onClick={this.handleCloseModal}>Close</button>
        </div>
        }
      </div>
    )
  }
}
