import React from 'react'
import { getDashboards } from '../util/store'
import LeftNav from './LeftNav'
import Dashboard from './Dashboard'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from "react-router-dom"
import DynamicForm from './form/DynamicForm'
import { createDashboard } from '../util/store'
import QueryView from './QueryView'
import EntitiesView from './EntitiesView'

export default class HomeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dashboards: [],
      showModal: false
    }

    this.onNewDashboard = this.onNewDashboard.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleDashboardFormSubmit = this.handleDashboardFormSubmit.bind(this)
    this.onDashboardDeleted = this.onDashboardDeleted.bind(this)
  }

  componentDidMount() {
    this.updateDashboards()
  }

  updateDashboards(forceRefresh) {
    getDashboards(forceRefresh)
      .then(data => {
        this.setState({dashboards: data})
      })
      .catch(err => {
        console.log(err)
      })
  }

  onNewDashboard() {
    this.setState({
      showModal: true
    })
  }

  onDashboardDeleted() {
    this.updateDashboards(true)
  }  

  handleCloseModal () {
    this.setState({
      showModal: false
    });
  }

  handleDashboardFormSubmit(data) {
    createDashboard(data)
      .then(() => {
        this.updateDashboards(true)
      })
    this.handleCloseModal()
  }

  render() {
    const dashboardData = {
      name: null,
      category: null,
      layout: {
        type: null
      },
      items: []
    }

    const dashboardFormConfig = [
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'category',
        type: 'string'
      },
      {
        name: 'layout',
        type: 'object',
        fields: [
          {
            name: 'type',
            type: 'string',
            inputType: 'combo',
            options: ['simple', 'columns', 'grid', 'draggable'],
            default: 'draggable'
          }
        ]
      }
    ]

    return (
      <div>
        <Router>
          <div>
            <Link to={"/query"}>query</Link>&nbsp;|&nbsp; 
            <Link to={"/entities"}>entities</Link>&nbsp;|&nbsp; 
            <button className="link-button" onClick={this.onNewDashboard}>new</button>
          </div>
          <div className="container">
            <LeftNav dashboards={this.state.dashboards} />
            <Switch>
              <Route exact path="/query">
                <QueryView />
              </Route>
              <Route exact path="/entities">
                <EntitiesView />
              </Route>
              <Route exact path="/dashboard/:id" render={ routeProps => {
                const dashboardId = routeProps.match.params.id

                let selectedDashboard
                if (this.state.dashboards) {
                  selectedDashboard = this.state.dashboards.find(el => el._id === dashboardId)
                }
                return <Dashboard config={selectedDashboard} onDeleted={this.onDashboardDeleted} />
              }} />
            </Switch>
          </div>
        </Router>
        {this.state.showModal &&
        <div className="fixedpanel">
          <DynamicForm
            data={dashboardData}
            config={dashboardFormConfig}
            onSubmit={this.handleDashboardFormSubmit}
          />
          <button onClick={this.handleCloseModal}>Close</button>
        </div>
        }
      </div>
    )
  }
}
