
export function getUrlParamsAsMap() {
  const urlParams = new URLSearchParams(window.location.search)
  const params = {}
  for (let key of urlParams.keys()) {
    params[key] = urlParams.get(key)
  }
  return params
}

// replace placeholders with query param values
export function insertParamsFromUrl(str) {
  const urlParams = new URLSearchParams(window.location.search)
  return insertParams(str, paramName => urlParams.get(paramName))
}

export function insertParamsFromMap(str, map) {
  return insertParams(str, paramName => map[paramName])
}

function insertParams(str, valGetter) {
  if (typeof str !== 'string') return str
  var match = str.match(/(:\S+)/g)
  if (match) {
    for (var param of match) {
      var val = valGetter(param.substring(1))
      if (val !== null) {
        str = str.replace(param, val)
      }
    }
  }
  return str
}
