import React from 'react';

export default function GridLayout(props) {
  // const config = this.props.config

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto',
    gap: '20px 20px'
  }

  return (
    <div style={containerStyle}>
      {props.items}
    </div>
  )
}
