import React from 'react';
import { getEntityByName } from '../../util/store'
import BasicTable from './BasicTable'
import DynamicForm from '../form/DynamicForm'

export default class AggregatedTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entity: null,
      showModal: false,
      hasError: false
    }

    this.onEdit = this.onEdit.bind(this)
    this.onEditItem = this.onEditItem.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCloseEditItem = this.handleCloseEditItem.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  static getConfiguration() {
    return [
      {
        name: 'groupBy',
        type: 'string'
      },
      {
        name: 'showTotal',
        type: 'bool'
      },
      {
        name: 'columns',
        type: 'array',
        itemType: 'object',
        fields: [
          {
            name: 'field',
            type: 'string'
          },
          {
            name: 'function',
            type: 'string',
            inputType: 'combo',
            options: ['sum', 'average', 'max', 'min', 'count']
          }
        ]
      }
    ]
  }

  componentDidMount() {
    getEntityByName(this.props.dataConfig.entity)
      .then(entity => {
        if (entity && entity.length) {
          this.setState({
            entity: entity[0]
          })
        }
      })
  }

  onEdit(row) {
    this.setState({
      showModal: true,
      items: row.items,
      modalState: 'showDetails'
    })
  }

  onEditItem(row) {
    this.props.onEdit(row)
  }

  handleCloseModal () {
    this.setState({
      showModal: false
    });
  }

  handleCloseEditItem(data) {
    if (this.state.modalCallback) {
      this.state.modalCallback(data)
    }
    this.setState({
      modalState: 'showDetails'
    });
  }

  handleSubmit() {
    this.setState({
      showModal: true,
      modalState: 'showDetails'
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    let config = this.props.config
    if (!config) {
      config = {
        columns: []
      }
    }

    if (!config.columns) {
      config.columns = []
    }

    const itemsTableConfig = {
      showEdit: true,
      columns: this.state.entity ? this.state.entity.fields : []
    }

    // create table configuration (columns)
    const tableConfig = {
      showEdit: true,
      columns: [
        {
          name: 'group'
        }
      ]
    }

    config.columns.forEach(c => {
      tableConfig.columns.push({
        name: c.field + '_' + c.function
      })      
    })

    // create records
    var groupBy = config['groupBy']
    const buckets = {}
    const data = this.props.data || []

    const uniqueFields = config.columns.reduce((accumulator, current) => {
      if (accumulator.indexOf(current.field) === -1) {
        accumulator.push(current.field)
      }
      return accumulator
    }, [])

    // create map of record objects
    data.forEach(item => {
      const groupName = item[groupBy]
      if (buckets[groupName] === undefined) {
        buckets[groupName] = {
          group: groupName,
          items: []
        }
      }
      buckets[groupName].items.push(item)
    })

    // generate basic metrics for each field
    uniqueFields.forEach(c => {      
      data.forEach(item => {
        const row = buckets[item[groupBy]]
        if (row[c] === undefined) {
          row[c] = {
            sum: 0,
            count: 0,
            min: null,
            max: null 
          }
        }

        row[c].sum += item[c]
        row[c].count++
        if (row[c].min === null) {
          row[c].min = item[c]
        } else {
          row[c].min = Math.min(row[c].min, item[c])
        }
        row[c].max = Math.max(row[c].max, item[c])
      })
    })

    config.columns.forEach(c => {
      var key = c.field + '_' + c.function
      Object.keys(buckets).forEach(group => {
        const row = buckets[group]
        if (c.function === 'sum') {
          row[key] = parseFloat(row[c.field].sum).toFixed(2)
        } else if (c.function === 'count') {
          row[key] = row[c.field].count
        } else if (c.function === 'average') {
          row[key] = (row[c.field].sum / row[c.field].count).toFixed(2)
        } else if (c.function === 'min') {
          row[key] = row[c.field].min
        } else if (c.function === 'max') {
          row[key] = row[c.field].max
        }
      })
    })

    let rows = Object.keys(buckets).map(key => {
      return buckets[key]
    })

    if (config.columns.length > 0) {
      var key = config.columns[0].field + '_' + config.columns[0].function
      rows = rows.sort((el1, el2) => {
        return el2[key] - el1[key]
      })
    }

    if (config.showTotal) {
      const row = {
        group: 'Total'
      }

      config.columns.forEach(c => {
        var key = c.field + '_' + c.function
        row[key] = 0
        rows.forEach(r => {
          row[key] += Number(r[key])
        })
        row[key] = row[key].toFixed(2)
      })

      rows.push(row)
    }

    return (
      <div>
        <BasicTable
          data={rows}
          config={tableConfig} 
          onEdit={this.onEdit}
        />
        {this.state.showModal &&
        <div className="fixedpanel">
          {this.state.modalState === 'showDetails' &&
          <div>
            <button onClick={this.handleCloseModal}>Close</button>
            <BasicTable
              data={this.state.items}
              config={itemsTableConfig} 
              onEdit={this.onEditItem}
            />
          </div>
          }
        </div>
        }
      </div>
    )
  }
}
