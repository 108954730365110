import React from 'react'
import FormField from './form/FormField'
import Creatable from './form/Creatable'
import {getEntityByName} from '../util/store'
import { getTodayDate } from '../util/date'

export default class EntityForm extends React.Component {
  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(window.location.search)

    this.data = {}
    if (props.data) {
      this.data = props.data
    } else if (props.config) {
      if (props.config.fields) {
        props.config.fields.forEach(item => {
          if (item.default != null) {
            var key = item.name
            var d = item.default

            // replace placeholder with query parameter value
            if (typeof d === 'string' && d.charAt(0) === ':') {
              this.data[key] = urlParams.get(d.substring(1))
            } else if (item.default === 'today()') {
              this.data[key] = getTodayDate()
            } else {
              this.data[key] = item.default
            }
          }
        })
      }
    }

    this.state = {
      entityName: null,
      entity: props.entity,
      entityError: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Store entityName in state so we can compare when props change.
    // Clear out any previously-loaded user data (so we don't render stale stuff).
    if (nextProps.entityName !== prevState.entityName) {
      return {
        entityName: nextProps.entityName,
        entityError: null,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this.updateEntity()
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateEntity()
  }

  updateEntity() {
    const shouldUpdate = this.props.entityName &&
      (!this.state.entity || this.props.entityName !== this.state.entity.name) &&
      !this.state.entityError

    if (shouldUpdate && !this.state.entityError) {
      getEntityByName(this.props.entityName)
        .then(data => {
          if (data && data.length) {
            this.setState({
              entity: data[0]
            })
          } else {
            this.setState({
              entityError: 'no entity available'
            })
          }
        })
        .catch(err => {
          this.setState({
            entityError: err
          })
        })
    }
  }

  handleSubmit(e) {
    this.props.onSubmit(this.data)
    e.preventDefault();
  }

  handleChange(name, value) {
    const fieldDef = this.state.entity.fields.find(field => field.name === name)
    if (fieldDef && fieldDef.type === 'number') {
      this.data[name] = parseFloat(value)
    } else {
      this.data[name] = value
    }
  }

  render() {
    const entity = this.state.entity
    const config = this.props.config || {}

    const fields = []
    if (entity) {
      entity.fields
      .filter((f, i) => {
        let fieldConfig = config[f.name]
        if (!fieldConfig && config.fields) {
          fieldConfig = config.fields.find(field => field.name === f.name )
        }

        if (!fieldConfig && !config.showUndefinedFields) {
          return false
        } else {
          return true
        }
      })
      .map((f, i) => {
        let fieldConfig = config[f.name]
        if (!fieldConfig && config.fields) {
          fieldConfig = config.fields.find(field => field.name === f.name )
        }

        let val
        if (!(typeof this.data[f.name] === 'undefined')) {
          val = this.data[f.name]
        } else if (fieldConfig && fieldConfig.default) {
          this.data[f.name] = val = fieldConfig.default
        }

        const name = (fieldConfig && fieldConfig.caption) || f.name
        let inputType = (fieldConfig && fieldConfig.inputType) || ''
        let component
        let cls
        if (inputType === 'creatable') {
          cls = Creatable
          component = React.createElement(cls, {            
            fieldName: f.name,
            fieldType: f.type,
            config: fieldConfig,
            value: val,
            onChange: this.handleChange
          })
        } else {
          component = (
            <FormField fieldName={f.name} fieldType={f.type} config={fieldConfig} key={i} value={val} onChange={this.handleChange} />
          )
        }

        return (
          <div className="dynamic-form-row" key={i}>
            <label className="dynamic-form-label">{name}</label>
            <span className="dynamic-form-input">
              {component}
            </span>
          </div>
          )
      }).forEach(f => {
        fields.push(f)
      })
    }

    return (
      <form className="dynamic-form" onSubmit={this.handleSubmit}>
        <ul>
        {fields}
        </ul>
        <input type="submit" value="Submit" />
      </form>
    )
  }
}
