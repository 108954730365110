import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom'
import GridLayout from 'react-grid-layout';
import { WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(GridLayout);

export default function DraggableGridLayout(props) {
  let config = props.config || []
  const layoutConfig = config.config

  // layoutConfig.forEach(config => {
  //   console.log(config)
  // })

  const edit = props.edit || false
  const onLayoutChange = props.onLayoutChange || function() {}

  let item = props.items[0]

  useEffect(() => {
    // console.log(props.refs)
  });

  return (
    <ResponsiveGridLayout className="layout" isDraggable={edit} isResizable={edit} layout={layoutConfig} cols={10} rowHeight={30} width={1200}
      autoSize={true} onLayoutChange={onLayoutChange} useCSSTransforms={false}>
      {props.items}
    </ResponsiveGridLayout>
  )
}
