import React from 'react';
import { getEntityByName } from '../../util/store'
import BasicTable from './BasicTable'

export default class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entity: null
    }
  }

  static getConfiguration() {
    return BasicTable.getConfiguration()
  }

  componentDidMount() {
    getEntityByName(this.props.dataConfig.entity)
      .then(entity => {
        if (entity && entity.length) {
          this.setState({
            entity: entity[0]
          })
        }
      })
  }

  render() {
    let config = this.props.config
    if (!config) {
      config = {}
    }

    const columns = config.columns || []
    const entity = this.state.entity

    const cols = []

    columns.forEach((col, i) => {
      const c = { name: col }
      if (entity) {
        const fieldDef = entity.fields.find(f => f.name === col)
        if (fieldDef) {
          c.type = fieldDef.type
        }
      }
      cols.push(c)
    })

    const updatedConfig = Object.assign({}, config)
    updatedConfig.columns = cols

    return (
      <BasicTable
        data={this.props.data}
        config={updatedConfig} 
        onEdit={this.props.onEdit}
        onDelete={this.props.onDelete}
        onSort={this.props.onSort}
        onLink={this.props.onLink}
        onUpdatedData={this.props.onUpdatedData}
      />
    )
  }
}
