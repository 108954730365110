
export function shallowCompare(a, b) {
  if (a == null && b == null) {
    return true
  }

  if (a == null || b == null) {
    return false
  }

  var aProps = Object.getOwnPropertyNames(a)
  var bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  for (let key in a){
    if(a[key] !== b[key]) return false
  }
  return true
}