import React from 'react';

export default function ColumnLayout(props) {
  const config = props.config

  const containerStyle = {
    display: 'flex'
  }

  const columns = []
  for (let i = 0; i < config.columns; i++) {
    const style = {
      flex: '50%',
      margin: '20px'
    }

    let items
    if (config.items) {
      items =
        config.items.map((itemConfig, index) => {
          if (Number(itemConfig.column) === (i+1)) {
            return index
          } else if (!itemConfig.column && i === 0) {
            return index
          }
          return null
        })
        .filter(i => {
          return i !== null
        })
        .map(i => {
          return props.items[i]
        })
    }

    columns.push(
      <div key={i} style={style}>
        {items}
      </div>
    )
  }

  return (
    <div style={containerStyle}>
      {columns}
    </div>
  )
}
