import React, { createRef } from 'react';
import Table from './Table'
import Cards from './Cards'
import Note from './Note'
import Kanban from './Kanban'
import Quote from './Quote'
import AggregatedTable from './AggregatedTable'
import Budget from './Budget'
import { getData, createRecord, updateRecord, deleteRecord, getDashboardByName } from '../../util/store'
import { insertParamsFromMap } from '../../util/url'
import { shallowCompare } from '../../util/common'
import { withRouter } from 'react-router-dom'

class DashboardItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: []
    }

    this.refreshCounter = 0

    this.handleNew = this.handleNew.bind(this)
    this.handleEditRecord = this.handleEditRecord.bind(this)
    this.handleDeleteRecord = this.handleDeleteRecord.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleClone= this.handleClone.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleLink = this.handleLink.bind(this)
    this.handleUpdatedData = this.handleUpdatedData.bind(this)
    this.handleConfigure = this.handleConfigure.bind(this)

    this.onNewCallback = this.onNewCallback.bind(this)
    this.onEditCallback = this.onEditCallback.bind(this)
    this.onDeleteRecordCallback = this.onDeleteRecordCallback.bind(this)

    this.component = null

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.config !== this.props.config ||
        !shallowCompare(prevProps.params, this.props.params)) {
      this.loadData()
    } else if (this.props.refresh !== this.refreshCounter) {
      this.refreshCounter = this.props.refresh
      this.loadData()
    }
  }

  getRef() {
    return this.containerRef
  }

  handleNew(e) {
    e.preventDefault();
    const formConfig = this.props.config.form
    this.props.onNew(this.props.config.data.entity, this.onNewCallback, formConfig)
  }

  handleEditRecord(data) {
    const formConfig = this.props.config.form
    this.props.onEditRecord(this.props.config.data.entity, data, this.onEditCallback, formConfig)
  }

  handleDeleteRecord(data) {
    const entityName = this.props.config.data.entity
    deleteRecord(entityName, data._id)
      .then((response) => {
        this.loadData()
      })
    // this is not necessary
    this.props.onDeleteRecord(this.props.config.data.entity, data, this.onDeleteRecordCallback)
  }

  handleDelete(data) {
    this.props.onDelete(this.props.config)
  }

  handleClone(data) {
    this.props.onClone(this.props.config)
  }

  handleEdit(data) {
    this.props.onEdit(this.props.config)
  }

  handleConfigure() {
    const cls = this.getClass()
    const formConfig = cls.getConfiguration()

    const config = this.props.config
    this.props.onConfigure(config, formConfig)
  }

  handleSort(item, newIndex, oldIndex) {
    const data = this.state.data

    // update data array
    data.splice(oldIndex, 1)
    data.splice(newIndex, 0, item)

    data.forEach((item, i) => {
      item._order = (i + 1)
    })

    const payload = data.map(item => {
      return {
        _id: item._id,
        _order: item._order
      }
    })

    const entityName = this.props.config.data.entity
    updateRecord(entityName, payload)
      .then((response) => {
      })
  }

  handleLink(link, item) {
    if (link.type === 'dashboard') {
      getDashboardByName(link.dashboard)
        .then(dashboard => {
          if (dashboard) {
            let params = insertParamsFromMap(link.params, item)
            this.props.history.push('/dashboard/' + dashboard._id + '?' + params)
          }
        })
    }
  }

  handleUpdatedData(item) {
    this.onEditCallback(item, true)
  }

  onNewCallback(data) {
    const entityName = this.props.config.data.entity
    data.order = 1
    if (this.state.data) {
      data.order = this.state.data.length + 1
    }

    createRecord(entityName, data)
      .then((response) => {
        this.loadData()
      })
  }

  onEditCallback(data, reload) {
    const entityName = this.props.config.data.entity
    updateRecord(entityName, data)
      .then((response) => {
        if (reload) {
          this.loadData()
        }
      })
  }

  onDeleteRecordCallback() {
    this.loadData()
  }

  loadData() {
    if (this.props.config.data) {
      getData(this.props.config.data)
        .then(data => {
          this.setState({
            data: data
          })
        })
    }
  }

  getClass() {
    const config = this.props.config
    let cls
    if (config.type === 'table') {
      cls = Table
    } else if (config.type === 'cards') {
      cls = Cards
    } else if (config.type === 'note') {
      cls = Note
    } else if (config.type === 'kanban') {
      cls = Kanban
    } else if (config.type === 'quote') {
      cls = Quote
    } else if (config.type === 'aggregated table') {
      cls = AggregatedTable
    } else if (config.type === 'budget') {
      cls = Budget
    }
    return cls
  }

  render() {
    const config = this.props.config
    const cls = this.getClass()

    this.component = React.createElement(cls, {
      data: this.state.data,
      dataConfig: config.data,
      config: config.config,
      onEdit: this.handleEditRecord,
      onDelete: this.handleDeleteRecord,
      onSort: this.handleSort,
      onLink: this.handleLink,
      onUpdatedData: this.handleUpdatedData
    })

    return (
      <div className="dashboard-item">
        <div className="dashboard-item-title">{config.title}</div>
        <button className="link-button" onClick={this.handleNew}>new record</button> 
        &nbsp;|&nbsp;
        <button className="link-button" onClick={this.handleEdit}>edit</button>
        &nbsp;|&nbsp;
        <button className="link-button" onClick={this.handleConfigure}>configure</button>
        &nbsp;|&nbsp;
        <button className="link-button" onClick={this.handleClone}>clone</button>
        &nbsp;|&nbsp;
        <button className="link-button" onClick={this.handleDelete}>delete</button>
        {this.component}
      </div>
    )
  }
}

export default withRouter(DashboardItem)

