import React from 'react'

export default class FormField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCreatableChange = this.handleCreatableChange.bind(this);
  }

  handleChange(event) {
    const fieldType = this.props.fieldType
    let value
    if (fieldType === 'bool') {
      value = event.target.checked
    } else {
      value = event.target.value
    }

    this.setState({value: value});
    this.props.onChange(this.props.fieldName, value)
  }

  handleCreatableChange(value, meta) {
    this.setState({value: value})
    this.props.onChange(this.props.fieldName, value)
  }

  render() {
    const config = this.props.config
    let value = (this.state.value != null) ? this.state.value : ''

    const fieldType = this.props.fieldType || config.type
    let inputType = (config && config.inputType) || 'textfield'
    if (fieldType === 'bool' && !(config && !!config.inputType)) {
      inputType = 'bool'
    }

    let field
    if (inputType === 'bool') {
      field = (
          <input type="checkbox" checked={value} onChange={this.handleChange} />
        )
    } else if (inputType === 'combo') {
      let options = []
      if (Array.isArray(config.options)) {
        options = config.options.map((o, i) => {
          return (
              <option key={i}>{o}</option>
            )
        })
      }
      options.unshift(<option key={-1}></option>)
      field = (
        <select className="dynamic-form-widget" value={value} onChange={this.handleChange}>
          {options}
        </select>
      )
    } else if (inputType === 'textarea') {
      field = (
        <textarea className="dynamic-form-widget" value={value} onChange={this.handleChange} />
      )
    } else if (inputType === 'textfield') {
      field = (
        <input className="dynamic-form-widget" type="text" value={value} onChange={this.handleChange} />
      )
    }

    return field
  }
}
