import { insertParamsFromUrl } from './url'

const hostname = 'https://framekit-api.martinkuba.com'
// const hostname = 'http://localhost:2345'

const cache = {
  dashboards: null
}

let setToken
export function setTokenSetter(fn) {
  setToken = fn
}

export function loginUser(credentials) {
  return fetch(hostname + '/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export function getDashboards(forceRefresh) {
  if (cache.dashboards && !forceRefresh) {
    return Promise.resolve(cache.dashboards)
  }

  return fetch(hostname + '/api/dashboards', {
    headers: {
      'x-access-token': getToken()
    }
  })
  .then(checkAuth)
  .then(response => {
    cache.dashboards = response.json()
    return cache.dashboards
  })
  .catch(err => {
    console.log('err:', err)
  })
}

export function getDashboard(id) {
  return getDashboards()
    .then(dashboards => {
      return dashboards.find(el => el._id === id)
    })
}

export function getDashboardByName(name) {
  return getDashboards()
    .then(dashboards => {
      return dashboards.find(el => el.name === name)
    })
}

export function createDashboard(data) {
  return fetch(hostname + `/api/dashboards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(data)
  })
  .then(checkAuth)
}

export function updateDashboard(data) {
  return fetch(hostname + `/api/dashboards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(data)
  })
  .then(checkAuth)
}

export function deleteDashboard(id) {
  return fetch(hostname + `/api/dashboards/${id}`, {
    method: 'DELETE',
    headers: {
      'x-access-token': getToken()
    },
  })
  .then(checkAuth)
}

export function getData(query) {
  // const urlParams = new URLSearchParams(window.location.search)

  const entity = query.entity
  let url = hostname + '/api/data/' + entity

  var params = []
  if (query.filter) {
    var filter = query.filter
    filter = insertParamsFromUrl(filter)
    params.push(['filter', filter])
  }

  if (query.sort) {
    params.push(['sort', query.sort])
  }

  params.push(['timeOffset', (new Date()).getTimezoneOffset()])

  if (params.length > 0) {
    url += '?'
    params.forEach(p => {
      url += p[0] + '=' + p[1] + '&'
    })
  }

  return fetch(url, {
    headers: {
      'x-access-token': getToken()
    }
  })
  .then(checkAuth)
  .then(response => {
    return response.json()
  })
}

export function getDistinctFieldValues(entity, field) {
  return fetch(hostname + '/api/distinct?entity=' + entity + '&field=' + field, {
    headers: {
      'x-access-token': getToken()
    }
  })
  .then(checkAuth)
  .then(response => {
    return response.json()
  })
}

export function getEntities() {
  return fetch(hostname + '/api/entities', {
    headers: {
      'x-access-token': getToken()
    }
  })
  .then(checkAuth)
  .then(response => {
    return response.json()
  })
}

export function getEntityByName(name) {
  return fetch(hostname + '/api/entities?filter=name=' + name, {
    headers: {
      'x-access-token': getToken()
    }
  })
  .then(checkAuth)
  .then(response => {
    return response.json()
  })
}

export function createEntity(data) {
  return fetch(hostname + `/api/entities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(data)
  })
  .then(checkAuth)
}

export function updateEntity(data) {
  return fetch(hostname + `/api/entities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(data)
  })
  .then(checkAuth)
}

export function deleteEntity(id) {
  return fetch(hostname + `/api/entities/${id}`, {
    method: 'DELETE',
    headers: {
      'x-access-token': getToken()
    },
  })
  .then(checkAuth)
}

export function createRecord(entityName, data) {
  return fetch(hostname + `/api/data/${entityName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(data)
  })
  .then(checkAuth)
}

export function updateRecord(entityName, data) {
  return fetch(hostname + `/api/data/${entityName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(data)
  })
  .then(checkAuth)
}

export function deleteRecord(entityName, id) {
  return fetch(hostname + `/api/data/${entityName}/${id}`, {
    method: 'DELETE',
    headers: {
      'x-access-token': getToken()
    },
  })
  .then(checkAuth)
}

function checkAuth(response) {
  if (response.status === 401) {
    clearToken()
  }
  return response
}

function getToken() {
  return sessionStorage.getItem('token')
}

function clearToken() {
  setToken('')
  // sessionStorage.removeItem('token')
}
