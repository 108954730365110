import React from 'react';

export default class BasicTable extends React.Component {
  constructor(props) {
    super(props)
    this.sortable = React.createRef()
    this.handleChange = this.handleChange.bind(this);
  }

  static getConfiguration() {
    return [
      {
        name: 'columns',
        type: 'array',
        itemtype: 'string'
      },
      {
        name: 'showHeaders',
        type: 'bool'
      },
      {
        name: 'showEdit',
        type: 'bool'
      },
      {
        name: 'showDelete',
        type: 'bool'
      },
      {
        name: 'showCheckbox',
        type: 'bool'
      },
      {
        name: 'checkboxField',
        type: 'string'
      },
      {
        name: 'sortable',
        type: 'bool'
      }
    ]
  }

  componentDidMount() {
    const config = this.props.config

    if (config && config.sortable !== false) {
      const node = window.jQuery(this.sortable.current)
      // const node = ReactDOM.findDOMNode(this)
      let oldPosition = null
      window.jQuery(node).sortable({
        cursor: "move",
        opacity: 1,
        tolerance: 'pointer',
        start: function(event, ui) {
          oldPosition = ui.item.index();
        },
        update: (event, ui) => {
          const data = this.props.data
          const newPosition = ui.item.index()

          const item = data[oldPosition]
          this.props.onSort(item, newPosition, oldPosition)

          window.jQuery(node).sortable('cancel')
          this.forceUpdate()
        }
      })
    }
  }

  handleChange(item, event) {
    const value = event.target.checked
    item[this.props.config.checkboxField] = value
    this.props.onUpdatedData(item)
  }

  render() {
    let config = this.props.config
    if (!config) {
      config = {}
    }

    const columns = config.columns || []

    const cols = []
    if (config.showCheckbox) {
      cols.push(
        <th key="th_check"></th>
      )
    }
    if (config.showEdit) {
      cols.push(
        <th key="th_edit1"></th>
      )
    }
    columns.forEach((col, i) => {
      cols.push(
        <th key={i}>{col.name}</th>
      )
    })
    if (config.links) {
      config.links.forEach(link => {
        cols.push(
          <th></th>
        )
      })
    }
    if (config.showEdit) {
      cols.push(
        <th key="th_edit"></th>
      )
    }
    if (config.showDelete !== false) {
      cols.push(
        <th key="th_delete"></th>
      )
    }

    const data = this.props.data
    const rows = []
    let val
    if (data) {
      data.forEach((item, i)  => {
        let fields = []

        if (config.showCheckbox) {
          val = item[config.checkboxField]
          fields.push((
            <td key={'check'}>
              <input key={'check' + item._id} type="checkbox" checked={val} onChange={this.handleChange.bind(null, item)} />
            </td>
          ))
        }

        if (config.showEdit) {      
          fields.push((
            <td key="edit1">
              <button className="link-button" onClick={this.props.onEdit.bind(this, item)}>edit</button>
            </td>
          ))
        }

        columns.forEach((col, i) => {
          val = item[col.name]
          if (val === true) {
            val = 'yes'
          } else if (val === false) {
            val = 'no'
          } else if (Array.isArray(val)) {
            val = val.reduce((accumulator, currentValue, index) => {
              if (index > 0) {
                accumulator += ', '
              }
              return accumulator + currentValue
            }, '')
          }

          if (isDateField(col) && val != null) {
            val = formatDate(new Date(val))
          }

          if (typeof val === 'string' && (val.indexOf('http://') === 0 || val.indexOf('https://') === 0)) {
            fields.push(
              <td key={i}>
                <a href={val} target="_blank" rel="noopener noreferrer">open link</a>
              </td>
            )
          } else {
            fields.push(
              <td key={i}>{val}</td>
            )
          }
        })

        if (config.links) {
          config.links.forEach(link => {
            fields.push((
              <td key={link.name}>
                <button className="link-button" onClick={this.props.onLink.bind(this, link, item)}>{link.name}</button>
              </td>
            ))
          })
        }

        if (config.showEdit) {
          fields.push((
            <td key="edit">
              <button className="link-button" onClick={this.props.onEdit.bind(this, item)}>edit</button>
            </td>
          ))
        }

        if (config.showDelete) {
          fields.push((
            <td key="delete">
              <button className="link-button" onClick={this.props.onDelete.bind(this, item)}>delete</button>
            </td>
          ))
        }

        rows.push(
          <tr key={i}>
            {fields}
          </tr>
        )
      })
    }

    return (
      <div>
        <table>
          {config.showHeaders !== false &&
          <thead>
            <tr>
            {cols}
            </tr>
          </thead>
          }
          <tbody ref={this.sortable}>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }
}

function isDateField(col) {
  if (col.name === '_dateCreated' || col.name === '_dateModified' || col.type === 'date') {
    return true
  }
  return false
}

function formatDate(date) {
  var d = date.getUTCDate();
  var m = date.getUTCMonth() + 1;
  var y = date.getUTCFullYear();
  return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}
