import React from 'react';

export default class Cards extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  static getConfiguration() {
    return [
      {
        name: 'labelField',
        type: 'string'
      },
      {
        name: 'descriptionField',
        type: 'string'
      }
    ]
  }

  componentDidMount() {
    const node = this.ref.current

    let oldPosition = null
    window.jQuery(node).sortable({
      cursor: "move",
      opacity: 1,
      tolerance: 'pointer',
      start: function(event, ui) {
        oldPosition = ui.item.index();
      },
      update: (event, ui) => {
        const data = this.props.data
        const newPosition = ui.item.index()

        const item = data[oldPosition]
        this.props.onSort(item, newPosition, oldPosition)

        window.jQuery(node).sortable('cancel')
        this.forceUpdate()
      }
    })
  }

  render() {
    const config = this.props.config || {}

    const containerStyle = {
      display: 'flex',
      flexWrap: 'wrap'
    }

    const itemStyle = {
      width: '200px',
      height: '100px',
      backgroundColor: '#cccccc',
      border: '1px solid #73AD21',
      borderRadius: '5px',
      overflow: 'auto',
      textAlign: 'center',
      padding: '2px', // padding inside the box
      margin: '4px' // space between boxes
    }

    const items = []
    const data = this.props.data
    if (data) {
      data.forEach((item, i)  => {
        items.push(
          <div id={item._id} key={i} style={itemStyle}>
            <div className="link-box" onClick={this.props.onEdit.bind(this, item)}>
              {item[config.labelField]}
            </div>
            <br/>
            {item[config.descriptionField]}
          </div>
        )
      })
    }

    return (
      <div style={containerStyle} ref={this.ref}>
        {items}
      </div>
    )
  }
}
