import React from 'react'
import FormField from './FormField'
import Creatable from './Creatable'
import ArrayField from './ArrayField'

export default class DynamicForm extends React.Component {
  constructor(props) {
    super(props)

    this.data = {}
    if (props.data) {
      this.data = props.data
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  handleSubmit(e) {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.data)
    }
    e.preventDefault();
  }

  handleChange(name, value) {
    this.data[name] = value
    if (this.props.onChange) {
      this.props.onChange(this.data)
    }
  }

  render() {
    const config = this.props.config || {}
    const nested = this.props.nested || false

    const data = this.data

    const fields = []
    let index = 0
    for (let fieldConfig of config) {
      index++
      if (fieldConfig.condition && data[fieldConfig.condition.field] !== fieldConfig.condition.value) {
        continue
      }

      const key = fieldConfig.name

      let val
      if (data && data[key] != null) {
        val = data[key]
      } else if (fieldConfig.default) {
        val = fieldConfig.default
        if (data) {
          data[key] = val
        }
      } else if (fieldConfig.type === 'object') {
        val = data[key] = {}
      }

      const name = fieldConfig.caption || key
      let inputType = fieldConfig.inputType || ''
      let component
      let cls

      if (fieldConfig.type === 'object') {
        cls = DynamicForm
        component = React.createElement(cls, {
          data: val,
          config: fieldConfig.fields,
          nested: true
        })
      } else if (fieldConfig.type === 'array') {
        cls = ArrayField
        component = React.createElement(cls, {
          fieldName: key,
          config: fieldConfig,
          value: val,
          onChange: this.handleChange
        })
      } else if (inputType === 'creatable') {
        cls = Creatable
        component = React.createElement(cls, {          
          fieldName: key,
          config: fieldConfig,
          value: val,
          onChange: this.handleChange
        })
      } else {
        component = (
          <FormField fieldName={key} fieldType={fieldConfig.type} config={fieldConfig} key={index} value={val} onChange={this.handleChange} />
        )
      }

      fields.push(
        <div className="dynamic-form-row" key={index}>
          <label className="dynamic-form-label">{name}</label>
          <span className="dynamic-form-input">
            {component}
          </span>
        </div>
      )
    }

    if (!nested) {
      return (
        <form className="dynamic-form" onSubmit={this.handleSubmit}>
          {fields}
          <input type="submit" value="Submit" />
        </form>
      )
    } else {
      return (
        <span>
          {fields}
        </span>
      )
    }
  }
}
