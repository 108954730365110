import React from 'react';

export default class Quote extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quote: this.getQuote(),
      previous: null
    }

    this.onPause = this.onPause.bind(this)
    this.onPrevious = this.onPrevious.bind(this)
    this.onNext = this.onNext.bind(this)
  }

  static getConfiguration() {
    return [
      {
        name: 'field',
        type: 'string'
      },
      {
        name: 'interval',
        type: 'number'
      }
    ]
  }

  componentDidMount() {
    const interval = (this.props.config && this.props.config.interval) || 60
    this.handle = setInterval(() => {
      this.refresh()
    }, interval * 1000)
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.refresh()
    }
  }

  componentWillUnmount() {
    if (this.handle) {
      clearInterval(this.handle)
    }
  }

  refresh() {
    if (this.state.paused) return
    this.setState({
      quote: this.getQuote(),
      previous: this.state.quote
    })
  }

  onPause() {
    this.setState({
      paused: !this.state.paused
    })
  }

  onPrevious() {
    if (this.state.paused) return
    this.setState({
      quote: this.state.previous
    })
  }

  onNext() {
    this.refresh()
  }

  getQuote() {
    const data = this.props.data

    if (!data || data.length === 0) {
      return
    }

    var randomIndex = Math.floor(Math.random() * data.length)
    return data[randomIndex]
  }

  render() {
    const errors = []
    let pausedText, text, other

    if (!this.props.config) {
      errors.push('Please configure')
    } else {
      if (!this.props.config.interval) {
        errors.push('Please set interval')
      }
      if (!this.props.config.field) {
        errors.push('Please set entity field to get data from')
      }
    }

    if (errors.length === 0) {
      text = ''
      other = ''

      let quote = this.state.quote
      if (quote) {
        text = quote[this.props.config.field]

        other = ' | ' + (quote['source'] || '')
        other += ' | ' + (quote['tags'] || '')
        other += ' | '
      }

      pausedText = this.state.paused ? 'unpause' : 'pause'
    }

    return (
      <div>
        {errors.length === 0 &&
        <div>
            <div>
              <button className="link-button" onClick={this.onPause}>{pausedText}</button>&nbsp;
              <button className="link-button" onClick={this.onPrevious}>previous</button>&nbsp;
              <button className="link-button" onClick={this.onNext}>next</button>&nbsp;
              <button className="link-button" onClick={this.props.onEdit.bind(this, this.state.quote)}>edit</button>
            </div>
            <br/>
            <div className="quote-text">{text}</div>
            <br/>
            <div className="quote-other">{other}</div>
        </div>
        }
        {errors.length > 0 &&
        <div>
          {errors}
        </div>
        }
      </div>
    )
  }
}
