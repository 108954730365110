import React from 'react'
import {
  Link,
} from "react-router-dom"

export default function LeftNav(props) {
  let rows = []

  const dashboards = props.dashboards
  const categorized = {}

  if (dashboards) {
    dashboards.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
    dashboards.forEach(d => {
      const category = d.category || 'none'
      if (!categorized[category]) {
        categorized[category] = []
      }
      categorized[category].push(d)
    })
  }

  Object.keys(categorized).forEach((category, i) => {
    const links = categorized[category]
    rows.push(
      <div key={i} className="dashboard-category">{category}</div>
    )
    links.forEach(item => {
      rows.push(
        <div key={item._id}>
        <Link to={"/dashboard/" + item._id}>{item.name}</Link>
        </div>
      )
    })
  })

  return (
    <div className="left-nav">
      {rows}
    </div>
  )
}
