import React, { useState } from 'react';

// const observe = require('./observable/observeComponent')
// const observe = require('./observable/collectSpans')
// const MeasureRender = require('./observable/MeasureRender')

// var HomeView = observe(require('./components/HomeView'));
// var HomeView = require('./components/HomeView.react');
import HomeView from './components/HomeView'
import Login from './components/Login'
import useToken from './useToken'
import {
  BrowserRouter, Route, Switch, Link, Redirect
} from "react-router-dom"
import './App.css';
import { setTokenSetter } from './util/store'

function App() {
  const { token, setToken } = useToken()

  // give this function to the store, so that when any call from API returns 401
  // the store can unset the token and also notify this component to redirect to login
  setTokenSetter(setToken)

  if(!token) {
    return <Login setToken={setToken} />
  }
  
  return (
    <BrowserRouter>
      <Route path="/login" render={ props => {
        if (token) {
          return <Redirect to="/" />
        }
        return <Login setToken={setToken} />
      }} />
      <Route path="/">
        <HomeView />
      </Route>      
    </BrowserRouter>
  );
}

export default App;

