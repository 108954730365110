import React from 'react'
import CreatableSelect from 'react-select/creatable'
import {getDistinctFieldValues} from '../../util/store'

export default class Creatable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
      options: []
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, meta) {
    let val
    if (Array.isArray(value)) {
      val = value.map(v => v.value)
    } else {
      val = (value && value.value) || ''
    }

    this.setState({
      value: val
    })
    this.props.onChange(this.props.fieldName, val)
  }

  // componentDidCatch(error, errorInfo) {
  // }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidMount() {
    const options = this.props.config.options
    if (Array.isArray(options)) {
      this.setState({
        options: options.map((o) => {
          return { value: o, label: o }
        })
      })
    }
    else if (typeof options === 'object') {
      getDistinctFieldValues(options.entity, options.field)
        .then((values) => {
          if (!Array.isArray(values)) return
          this.setState({
            options: values.map((o) => {
              return { value: o, label: o }
            })
          })
        })
    }
  }

  render() {
    const config = this.props.config
    let value = this.state.value
    let options = this.state.options

    if (config.multi && Array.isArray(value)) {
      value = value.map(v => { return { value: v, label: v }})
    } else if (value !== undefined) {
      value = { value: value, label: value}
    }

    const inputProps = {
      options: options,
      value: value,
      isClearable: true,
      isMulti: !!config.multi,
      onChange: this.handleChange
    }

    return (
      <div>
        <CreatableSelect
          {...inputProps}
        />
      </div>
    )
  }
}
