import React from 'react'
import { getEntities, getEntityByName, getData, createEntity,
  updateEntity, deleteEntity } from '../util/store'
import BasicTable from './dashboard/BasicTable'
import DynamicForm from './form/DynamicForm'

const entityFormConfig = [
  {
    name: 'name',
    type: 'string'
  },
  {
    name: 'fields',
    type: 'array',
    itemType: 'object',
    fields: [
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string',
        inputType: 'combo',
        options: ['string', 'number', 'bool', 'date', 'timestamp']
      }
    ]
  }
]

export default class EntitiesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entities: [],
      entity: null,
      showModal: false
    }

    this.onNew = this.onNew.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.updateEntities()
  }

  updateEntities() {
    getEntities()
      .then(entities => {
        this.setState({
          entities: entities
        })
      })
  }

  onNew() {
    const component = this

    const entityData = {
      name: null,
      category: null,
      layout: {
        type: null
      },
      items: []
    }

    this.setState({
      showModal: true,
      modalData: entityData,
      modalCallback: save
    })

    function save(data) {
      createEntity(data)
        .then(() => {
          component.updateEntities()
        })
    }
  }

  onEdit(item) {
    const component = this

    this.setState({
      showModal: true,
      modalData: item ,
      modalCallback: save
    });

    function save(data) {
      updateEntity(data)
        .then(() => {
          component.updateEntities()
        })
    }
  }

  onDelete(data) {
    deleteEntity(data._id)
      .then(() => {
        this.updateEntities()
      })
  }

  handleCloseModal () {
    this.setState({
      showModal: false
    });
  }

  handleSubmit(data) {
    if (this.state.modalCallback) {
      this.state.modalCallback(data)
    }
    this.handleCloseModal()
  }

  render() {
    const value = this.state.query

    const config = {
      columns: [{
        name: 'name'
      }],
      showEdit: true,
      showDelete: true
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <button className="link-button" onClick={this.onNew}>new</button>
        <BasicTable
          data={this.state.entities}
          config={config}
          onEdit={this.onEdit}
          onDelete={this.onDelete}
        />
        {this.state.showModal &&
        <div className="fixedpanel">
          <DynamicForm
            data={this.state.modalData}
            config={entityFormConfig}
            onSubmit={this.handleSubmit}
          />
          <button onClick={this.handleCloseModal}>Close</button>
        </div>
        }
      </div>
    )
  }
}
